import React from 'react';
import Header from '../../Components/Header';

export default function Home() {
    return (
        <div>
            <Header />
            <h1>PAGINA PRINCIPAL</h1>
        </div>
    );
}